<template>
  <div class="login">
    <img class="login-icon" src="@/assets/img/login-icon.png" />
    <form class="login-body" @submit.prevent="onSubmit">
      <div class="title-block">
        <div class="password-login">Masuk via kata sandi</div>
        <div v-if="isIOS" class="register" @click="goRegister">Daftar</div>
      </div>
      <div class="input-block">
        <input v-model.trim="phone" type="tel" autofocus placeholder="Masukkan nomor handphone dengan awalan 08">
      </div>
      <div class="input-block">
        <input v-model.trim="password" maxlength="8" type="password" placeholder="Masukkan 8 digit angka password">
      </div>
      <div class="bottom-block">
        <p @click="goMessageLogin">Login dengan SMS</p>
        <p @click="forgetPassword">Lupa Password？</p>
      </div>
      <button type="button" class="login-btn" @click="onSubmit">Login Password</button>
    </form>
    <img class="footer" src="@/assets/img/login-footer.png" />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { Toast } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword } from '@/utils/validator'
import { isIOS } from '@/utils/detect'

export default {
  name: 'Login',
  data () {
    return {
      phone: '',
      password: '',
      isIOS
    }
  },
  methods: {
    forgetPassword () {
      const { query } = this.$route
      this.$router.push({
        path: '/forgetPassword',
        query
      })
    },
    validate () {
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      if (!this.password) {
        Toast('Masukkan 8 digit angka password')
        return false
      }
      if (!validatePassword(this.password)) {
        Toast('Password salah')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        lodrginType: 1,
        usdrerPhone: this.phone,
        usdrerPassword: this.password
      }
      request.post('usdrerLogin', postData)
        .then(res => {
          this.$store.commit('recordInfo', {
            type: 'userInfo',
            value: res
          })
          Cookies.set('userInfo', JSON.stringify(res))
          const { redirectUrl = '/' } = this.$route.query
          this.$router.push(redirectUrl)
        })
        .finally(() => {
          this.flag = false
        })
    },
    goMessageLogin () {
      const { query } = this.$route
      this.$router.push({
        path: '/messageLogin',
        query
      })
    },
    goRegister () {
      const { query } = this.$route
      this.$router.push({
        path: '/register',
        query
      })
    }
  }
}
</script>

<style scoped lang="less">
.login {
  min-height: 100vh;
  background-color: #F4F4F4;
  overflow: auto;
}

.login-icon {
  width: 750px;
  height: 517px;
  margin: 106px auto 0;
}

.login-body {
  position: relative;
  margin: 0 30px;
}

.title-block {
  display: flex;
  height: 88px;
  line-height: 88px;

  & > div {
    flex: 1;
    font-size: 30px;
    text-align: center;
  }
}

.password-login {
  border-radius: 17px 3px 3px 17px;
  color: #1C0A1B;
  background-color: #CFCFCF;
}

.register {
  border-radius: 3px 17px 17px 3px;
  color: #FFF;
  background-color: #E82225;
}

.input-block {
  margin-top: 24px;

  input {
    display: block;
    width: 100%;
    height: 88px;
    line-height: 88px;
    padding-left: 25px;
    border: none;
    border-radius: 17px;
    box-sizing: border-box;
    font-size: 30px;
    color: #333;
    background-color: #E6E6E6;

    &::placeholder {
      color: #808080;
      font-size: 26px;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.bottom-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  p {
    line-height: 42px;
    font-size: 30px;
    color: #1C0A1B;
  }
}

.login-btn {
  .submit-btn;
  width: 100%;
  color: #1C0A1B;
  margin-top: 54px;
  background: linear-gradient(180deg,#919191 0%,#C4C4C4 33%,#FFFFFF 69%,#808080 100%);
}

.footer {
  width: 358px;
  height: 85px;
  margin: 65px auto 80px;
}
</style>
